.container {
  max-width: 1680px;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
}

.btn-main,
.btn-light,
.btn-dark {
  display: inline-block;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}

.btn-main {
  color: #333;
  background-color: #ffbc00;
}

.btn-light {
  color: #333;
  background-color: #f4f4f4;
}

.btn-dark {
  color: #f4f4f4;
  background-color: #333;
}

button[class^='btn-']:hover,
a[class^='btn-']:hover,
input[class^='btn-']:hover {
  background-color: #ffbc00;
}

.bg-main {
  background: #ffbc00;
  color: #fff;
}

.bg-dark {
  background: #333;
  color: #fff;
}

.bg-light {
  background: #f4f4f4;
  color: #000;
}

.bg-medium {
  background: #ccc;
  color: #000;
}

.lead {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

.py-4 {
  padding: 4rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}

.item-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  transform: translateY(-20%);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 300ms;
}

.item-text-title {
  font-size: 2rem;
  padding: 0 1rem;
  margin: 5px 0 0 0;
}
.item-text-category {
  text-transform: uppercase;
  font-size: 1.2rem;
  opacity: 0.7;
  margin: 0;
}

#about-a .bio-image {
  grid-area: bioimage;
}

#about-a .bio {
  grid-area: bio;
  border-left: 3px solid #ffbc00;
  padding: 0.8rem;
}

#main-footer .footer-content .social .fab {
  margin-right: 1rem;
  border: 2px #fff solid;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0.5rem;
}
#main-footer .footer-content .social .fab:hover {
  background: #ffbc00;
}

@media (max-width: 800px) {
  #header-home .header-content {
    padding-top: 5rem;
  }
}

@media (max-width: 500px) {
  #header-home .header-content {
    display: none;
  }
}

@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3rem;
  }
}

@media (max-height: 330px) {
  #header-home .header-content h1 {
    font-size: 2rem;
  }
}
